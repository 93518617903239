.products .mantine-Pagination-item{
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #C40201;
    background: none;
}

.products .products__hero {
    background: url("../../assets/img/products/back.png") no-repeat;
    width: 100%;
    height: 50vh;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.products .products__hero h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #FFFFFF;
    text-transform: capitalize;
}

.products .products__filter {
    padding: 30px 0;
    margin-top: 30px;
    margin-bottom: 20px;
}

.products .products__filter .col {
    width: 120px;
}

.products .products__filter .col p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #2A2A2A;
    text-align: center;
    text-transform: capitalize;

}

.products .products__filter .col p.active {
    color: #AB3331;
    font-weight: 700;
}

.products .products__items .pagination{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px;
}


@media (max-width: 1200px) {
    .products .products__hero {
        height: 45vh;
    }
}

@media (max-width: 992px) {
    .products .products__hero {
        height: 40vh;
    }
}

@media (max-width: 768px) {
    .products .products__hero {
        height: 40vh;
    }
}