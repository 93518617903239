.footer {
    background: #292929;
    padding: 82px 0;
}

.footer__first img {
    width: 230px;
    height: 36.32px;
}

.footer .footer__first {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.footer p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #FFFFFF;
}

.footer button {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #FFFFFF;
    padding: 10px 15px;
    background: #C40201;
    margin-top: 32px;
    border: none;
}

.footer button a {
    text-decoration: none;
    color: #FFFFFF;
}

.footer h5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 0;
    margin-top: 16px;
}

.footer h5 svg {
    margin-right: 10px;
}

.footer .footer__flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 40px;
}

.footer .footer__flex button {
    margin: 0;
    border: 1px solid #C40201;
    background: none;
    margin-right: 20px;
}

.footer .footer__flex a {
    text-decoration: none;
}

.footer .footer__flex button:hover {
    background: #C40201;
}

.footer .footer__flex .footer__icons svg {
    margin-right: 20px;
}

.footer__mobile {
    display: none;
    background: #292929;
}

.footer__mobile .scrool__top {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #C40201;
}

.footer__mobile .scrool__top p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
    margin-left: 20px;
}

.footer__mobile .footer__column {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px 0;
}

.footer__mobile .footer__column img {
    width: 240px;
    height: auto;
    margin-bottom: 20px;
}

.footer__mobile .footer__column p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
}

.footer__mobile .footer__column h5 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: #FFFFFF;
}

.footer__mobile .footer__column h5 svg {
    margin-right: 10px;
}

.footer__mobile .footer__column .btn__feedback {
    background: #C40201;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #FFFFFF;
    padding: 8px 43px;
    width: 200px;
    border: 1px solid #C40201;
    margin: 15px 0;
}

.footer__mobile .footer__column .btn__feedback a {
    color: #FFFFFF;
    text-decoration: none;
}

.footer__mobile .footer__column .btn__email {
    background: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #FFFFFF;
    padding: 8px 43px;
    width: 200px;
    border: 1px solid #C40201;
    margin-bottom: 15px;
}

.footer__mobile .footer__column .btn__email a {
    color: #FFFFFF;
    text-decoration: none;
}

.footer__mobile .footer__column .footer__icons {
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}


@media (max-width: 768px) {
    .footer {
        display: none;
    }

    .footer__mobile {
        display: block;
    }
}