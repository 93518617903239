.about .about__hero {
    background: url("../../assets/img/contact.png") no-repeat;
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.about .about__hero h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 70px;
    text-transform: capitalize;
}

.about .about__about-1 {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 40px;
    padding-bottom: 40px;
}

.about .about__about-1 img {
    position: absolute;
    right: 0;
    top: 45px;
    width: 45%;
    height: 80%;
    object-fit: cover;
}

.about .about__about-1 h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    color: #622B2B;
    margin-top: 50px;
    margin-bottom: 38px;
}

.about .about__about-1 p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #5B5252;
    margin-bottom: 20px;
}

.about .about__about-2 {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 40px;
    padding-bottom: 40px;
}

.about .about__about-2 img {
    position: absolute;
    left: 0;
    top: 45px;
    width: 45%;
    height: 80%;
    object-fit: cover;
}

.about .about__about-2 h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    color: #622B2B;
    margin-top: 60px;
    margin-bottom: 38px;
}

.about .about__about-2 p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #5B5252;
    margin-bottom: 50px;
}

.about .about__texts {
    padding: 130px 0;
}

.about .about__texts .margin {
    margin-bottom: 120px;
}

.about .about__texts .about__text h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #622B2B;
    margin-bottom: 38px;
}

.about .about__texts .about__text p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    text-align: center;
    color: #5B5252;
}

.about .about__form {
    width: 100%;
    background: #AB3331;
    padding: 50px 0 30px;
}

@media (max-width: 768px) {
    .about .about__texts {
        padding: 0;
        padding-bottom: 130px;
    }

    .about .about__about-1 img {
        position: relative;
        width: 100%;
        top: 0;
        height: auto;
    }

    .about .about__about-1 h4 {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 32px;
    }

    .about .about__about-1 p {
        text-align: center;
    }

    .about .about__about-2 img {
        position: relative;
        width: 100%;
        top: 0;
        height: auto;
    }

    .about .about__about-2 h4 {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 32px;
    }

    .about .about__about-1 p {
        text-align: center;
    }
}