.product .product__hero {
    background: url("../../assets/img/products/back.png") no-repeat;
    width: 100%;
    height: 50vh;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.product .product__hero h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #FFFFFF;
    text-transform: capitalize;
}

.product .product__url p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #2A2A2A;
    margin-top: 40px;
    margin-bottom: 25px;
    text-transform: capitalize;
}

.product .product__url p a {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #2A2A2A;
    margin-top: 40px;
    margin-bottom: 25px;
    text-transform: capitalize;
    text-decoration: none;
}

.product .product__items {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #5B5252;
    text-align: center;
    margin-bottom: 40px;
}

.product .product__items h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #5B5252;
    margin: 40px 0;
}

.product .product__info img {
    width: 100%;
    min-height: 360px;
    object-fit: cover;
}

.product .product__info .product__info-texts h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #2A2A2A;
    margin: 0;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.product .product__info .product__info-texts p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #C40201;
}

.product .product__info .product__info-size {
    margin-top: 30px;
}

.product .product__info .product__info-size h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #2A2A2A;
    margin: 0;
    margin-bottom: 10px;
}

.product .product__info .product__info-size p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #5B5252;
    text-transform: uppercase;
}

.product .product__info .product__info-description {
    margin-top: 20px;
}

.product .product__info .product__info-description h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #2A2A2A;
    margin: 0;
    margin-bottom: 10px;
}

.product .product__info .product__info-description p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #5B5252;
}

.product .product__description-mobile {
    display: none;
}

.product .product__description-mobile .flex {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.product .product__description-mobile .flex h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #2A2A2A;
}

.product .product__description-mobile .flex p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 25px;
    text-align: center;
    color: #5B5252;
    width: 250px;
}

@media (max-width: 768px) {
    .product .product__info img {
        min-height: 230px;
    }

    .product .product__info .product__info-size p {
        font-size: 15px;
        line-height: 30px;
    }

    .product .product__url p {
        text-align: center;
    }

    .product .product__info .product__info-texts {
        margin-bottom: 60px;
    }

    .product .product__info .product__info-description {
        display: none;
    }

    .product .product__description-mobile {
        display: block;
        margin-top: 30px;
    }
}