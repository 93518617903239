.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    text-decoration: none;
}

.card img {
    width: 264px;
    height: 258px;
    object-fit: cover;
}

.card h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #2A2A2A;
    margin-bottom: 8px;
    text-transform: capitalize;
}

.card p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #C40201;
}

@media (max-width: 768px) {
    .card img {
        height: 210px;
    }
}