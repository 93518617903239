.slick-dots {
    bottom: -40px !important;
}

.slick-dots li button:before{
    font-size: 10px !important;
    color: #C40201 !important;
}

.home .home__hero {
    background: url("../../assets/img/banner.png") no-repeat;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.home .home__hero h3 {
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 50px;
    color: #FFFFFF;
}

.home .home__hero svg {
    position: absolute;
    display: block;
    bottom: 30px;
    left: 50%;
    right: 50%;
}

@media (max-width: 1200px) {
    .home .home__hero {
        height: 70vh;
    }
}

@media (max-width: 992px) {
    .home .home__hero {
        height: 55vh;
    }
}

@media (max-width: 768px) {
    .home .home__hero {
        height: 100vh;
    }

    .home .home__hero svg {
        display: block;
    }
}

.home .category {
    margin: 50px 0;
}

.home .category .carousel_slider {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.home .category .carousel_slider .border {
    width: 420px !important;
    height: 400px;
    position: relative;
}

.home .category .carousel_slider .border::before {
    content: "";
    width: 420px;
    height: 100%;
    background: linear-gradient(0deg, rgba(171, 51, 49, 0.3), rgba(171, 51, 49, 0.3));
    position: absolute;
    z-index: 5;
}

.home .category .carousel_slider .border img {
    width: 420px !important;
    height: 400px;
    object-fit: cover;
}

.home .category .carousel_slider .border h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

@media (max-width: 768px) {
    .home .category .carousel_slider{
        width: auto !important;
        margin-bottom: 20px;
    }
}

.home .home__products {
    padding: 30px 0;
}

.home .home__products h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 37px;
    color: #C40201;
    text-align: center;
}

.home .home__products .home__products-filter {
    margin: 24px auto;
}

.home .home__products .home__products-filter a{
    color: #2A2A2A;
    text-decoration: none;
}

.home .home__products .home__products-filter p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #2A2A2A;
    width: 100%;
    text-align: center;
    text-transform: capitalize;
}

.home .home__products .home__products-filter p.active {
    color: #C40201;
    font-weight: 700;
}

.home__sale {
    background: url("../../assets/img/sale.png") no-repeat;
    width: 100%;
    height: 320px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.home__sale button {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    background: #C40201;
    padding: 15px 23px;
    border: none;
    margin-bottom: 17px;
    text-transform: uppercase;
}

.home__sale h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 49px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
}

.home__sale svg {
    display: none;
}

@media (max-width: 576px) {
    .home__sale {
        height: 100vh;
    }

    .home__sale svg {
        display: block;
        position: absolute;
        bottom: 60px;
    }
}

.home .home__about {
    position: relative;
    width: 100%;
    height: auto;
    padding: 40px 0;
}

.home .home__about img {
    position: absolute;
    left: 0;
    top: 40px;
    width: 45%;
    height: 80%;
    object-fit: cover;
}

.home .home__about h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    color: #622B2B;
    margin-top: 40px;
    margin-bottom: 52px;
}

.home .home__about p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #5B5252;
    margin-bottom: 42px;
}

.home .home__about button {
    padding: 8px 35px;
    background: none;
    border: 1px solid #C40201;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 80px;
    color: #C40201;
    text-transform: capitalize;
}

.home .home__about button a {
    color: #C40201;
    text-decoration: none;
}

@media (max-width: 768px) {
    .home .home__about img {
        position: relative;
        width: 100%;
        top: 0;
        height: auto;
    }

    .home .home__about h4 {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 32px;
    }

    .home .home__about p {
        text-align: center;
    }

    .home .home__about button {
        margin: 0 auto;
    }
}